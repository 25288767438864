export default function Error() {
    return(
        <main className="bg-background 
        w-full 
        h-screen 
        flex 
        items-center 
        justify-center 
        box-border">

            <div className="flex 
            items-center 
            justify-center 
            flex-col">

                <img className="w-64 
                mb-4" 
                src="/404.webp"/>

                <a className="bg-primary 
                rounded-md 
                w-64 
                p-1 
                font-bold 
                text-background 
                cursor-pointer 
                text-center" href="/pagina-a">Voltar para o início</a>
            </div>
        </main>
    );
}