export default function Obrigado(){
    return(
        <main className="bg-background w-full h-full flex flex-col items-center content-center">
            <div className="flex justify-center items-center w-full my-6 lg:my-10">
                <img className="h-16" src="/android-chrome-192x192.png"/>
            </div>

            <div className="w-3/4 lg:w-2/3 text-center my-2">
                <h1 className="text-4xl font-bold">
                <b className="text-primary font-bold">Parabéns,</b> você fez uma ótima escolha adquirindo essa IA
                </h1>

                <h3 className="text-center text-xl my-4 font-bold">
                    Agora só falta <b className="text-primary font-bold">fazer o seu registro e acessar a IA</b>, abaixa estará o passo a passo do que é necessário:
                </h3>

                <div className="my-10">
                    <p className="text-xl mb-4">
                        1. Acessar a plataforma <b className="text-primary font-bold">clicando no botão abaixo</b> e fazer seu registro tocando em <b className="text-primary font-bold">"REGISTRE-SE”</b>
                    </p>

                    <a className="bg-primary font-extrabold text-2xl p-1 px-4 rounded-lg box-border" href="https://afiliados.mmabet.com/visit/?bta=42776&brand=mmabet" target="_blank">REGISTRAR AQUI</a>
                </div>

                <div className="my-10">
                    <p className="text-xl my-4">
                    2. Depois que feito o registro na plataforma acima; já terá o acesso a IA  <b className="text-primary font-bold">clicando no botão abaixo:</b>
                    </p>

                    <a className="bg-primary font-extrabold text-2xl p-1 px-4 rounded-lg box-border" href="https://iabet.com.br/" target="_blank">ACESSAR ECHO IA</a>
                </div>

                <p className="text-xl my-4">
                    Agora é só entrar na Echo IA e começar suas entradas e aproveitar!
                </p>

                <p className="text-xl my-4">
                    <i className="text-base">
                        *Também foi enviado por e-mail estas mesmas instruções*
                    </i>
                </p>

                <div className="my-10">
                    <p className="text-xl my-4">
                        Possui alguma dúvida? Chama no WhatsApp, clicando no botão abaixo, que iremos te ajudar!
                    </p>

                    <a className="bg-primary font-extrabold text-2xl p-1 px-4 rounded-lg box-border" href="https://wa.me/+5554991423251?text=Oii!%20Preciso%20de%20ajuda%20para%20acessar%20o%20aplicativo,%20pode%20me%20ajudar?" target="_blank">CHAMAR NO WHATSAPP</a>
                </div>
            </div>
            
        </main>
    );
}