import { BsFillArrowRightSquareFill } from "react-icons/bs";
import Carousel from './carousel.js';


export default function PaginaA(){
    return(
        <main className="bg-background w-full h-full flex flex-col items-center content-center">
            <div className="flex justify-center items-center w-full my-6 lg:my-10">
                <img className="h-16" src="/android-chrome-192x192.png"></img>
            </div>
            
            <article className="w-11/12 lg:w-3/5">
                <header className="flex w-full justify-center items-center">
                    <div>
                        <h1 className="text-contrast text-3xl lg:text-4xl font-bold mb-2">Sua vantagem secreta para vencer as máquinas</h1>
                        <h3 className="text-contrast">São 12 jogos que essa Inteligência Artificial domina os momentos exatos para faturar no mercado de apostas</h3>
                    </div>

                    <div className="hidden lg:block">
                        <img src="/header-phone.webp"/>
                    </div>
                </header>

                <section className="grid lg:flex p-6 rounded-lg w-full bg-second my-10">
                    <div className="w-full lg:w-1/3 px-4 lg:border-r border-contrast">
                        <p className="text-center">
                        O aplicativo desenvolvido para encontrar entradas nos jogos mais pagantes
                        </p>
                    </div>
                    <div className="w-full my-4 lg:w-1/3 border-t pt-2 lg:pt-0 lg:my-0 px-4 lg:border-r lg:border-t-0 border-contrast">
                        <p className="text-center">
                        Sem tempo para pausa. Funciona 24 horas por dia e 7 dias por semana
                        </p>
                    </div>
                    <div className="w-full border-t border-contrast pt-2 lg:w-1/3 lg:border-0 lg:my-0 px-4">
                        <p className="text-center">
                        O único aplicativo que utiliza inteligência artificial com 98% de assertividade
                        </p>
                    </div>
                </section>

                <section className="flex flex-col p-2 lg:p-6 rounded-lg w-full bg-second my-10 items-center justify-center">
                    <div className="items-center justify-center flex flex-col">
                        <h2 className="font-bold py-2 text-xl">
                            Como funciona?
                        </h2>
                        <p className="text-left w-11/12 lg:w-10/12">
                            São 12 jogos que podem ser usados livremente em que a Inteligência Artificial trabalha para encontrar possíveis entradas lucrativas!
                        </p>
                    </div>
                    <div className="flex flex-col lg:w-10/12 justify-center items-center m-4 w-11/12">
                        <p className="pb-4">
                        O mercado de igaming é o único mercado que transformou jovens de baixa renda em multimilionários em tão pouco tempo, mas afinal de contas o que é esse mercado?
                        <br/>
                        <br/>
                        Os jogos de igaming são basicamente jogos probabilísticos, isso significa que a cada jogada existe um percentual de chance que não se altera nunca em todas as rodadas. Se você pegar um dado de 6 lados e jogar para o alto você terá 16,67% de cair qualquer número, isso quer dizer que, se você jogar o dado 100 vezes para o alto, aproximadamente 16 vezes irá cair o mesmo número. Isso é matemática básica, tenho certeza que deve ter estudado na escola, talvez não se lembre, mas não tem problema eu te garanto que você não precisará fazer contas de matemática.
                        <br/>
                        <br/>
                        Do mesmo modo que jogar 1 dado para cima você estará apostando com a probabilidade, os jogos de igaming também irão funcionar da mesma maneira. Quero lhe dizer que a matemática aumenta as suas chances de ganho desde que você saiba interpretar as rodadas anteriores, assim você sairá de uma probabilidade de 16% de chance para até 98% de probabilidade de vitória em cada rodada.
                        <br/>
                        <br/>
                        A <b>Echo IA</b> é uma Inteligência Artificial que analisa dados a todo momentos em tempo real e entrega como que você precisa dar as entradas, não tem esforço algum. A probabilidade desta IA faz você sair de uma probabilidade de acerto de 16% para até <b>98% de assertividade por rodada.</b> 
                        </p>
                        <a className="cursor-pointer bg-primary text-background font-extrabold p-2 w-full text-center mt-2 rounded-lg" href="https://pepper.com.br/checkout/index.html?p=93475&o=107543" target="_blank">COMPRAR AGORA</a>
                    </div>
                </section>

                <section className="flex flex-col p-2 py-4 lg:py-4 lg:p-6 rounded-lg w-full bg-second my-10 items-center justify-center">
                    <div className="items-center justify-center flex flex-col">
                        <h2 className="font-bold text-xl">
                            Depoimentos
                        </h2>
                        <p className="lg:text-center w-11/12 lg:w-10/12">
                            Temos mais de 2 mil pessoas que utilizam o aplicativo, com isso recebemos diversas fotos e vídeos de resultados que os mesmos obtiveram, veja:
                        </p>
                    </div>

                    <Carousel />

                    <div className="flex flex-col justify-center items-center m-4 w-full">

                        <p className="text-base text-contrast w-11/12 lg:w-10/12 mt-2">
                            *As fotos de perfis foram removidas por motivos de privacidade
                        </p>
                        <a className="cursor-pointer bg-primary text-background font-extrabold p-2 w-11/12 lg:w-10/12 text-center mt-2 rounded-lg" href="https://pepper.com.br/checkout/index.html?p=93475&o=107543" target="_blank">COMPRAR AGORA</a>
                    </div>
                </section>

                <section className="flex flex-col p-6 rounded-lg w-full bg-second my-10 items-center justify-center">
                    <div className="items-center justify-center flex flex-col mb-4">
                        <h2 className="font-bold text-xl">
                            Valores
                        </h2>
                        <p className="text-center w-11/12 lg:w-9/12">
                        O aplicativo que movimenta mais contas bancárias do que qualquer emprego que possa existir está com em promoção neste exato momento, peço que avalie cuidadosamente a oportunidade que está diante de você:
                        </p>
                    </div>

                    <article className="justify-center">
                        <list>
                            <h3 className="font-bold">
                                O QUE VOCÊ ESTÁ PRESTES A OBTER
                            </h3>
                            <item className="flex items-center">
                                <BsFillArrowRightSquareFill className="text-primary mr-2"/>
                                <p>
                                    Aplicativo que funciona com IA
                                </p>
                            </item>
                            <item className="flex items-center">
                                <BsFillArrowRightSquareFill className="text-primary mr-2"/>
                                <p>
                                    Possui assertividade de até 98%
                                </p>
                            </item>
                            <item className="flex items-center">
                                <BsFillArrowRightSquareFill className="text-primary mr-2"/>
                                <p>
                                    Fácil de utilizar e totalmente integrado
                                </p>
                            </item>
                            <item className="flex items-center">
                                <BsFillArrowRightSquareFill className="text-primary mr-2"/>
                                <p>
                                    Não precisa baixar nada
                                </p>
                            </item>
                            <item className="flex items-center">
                                <BsFillArrowRightSquareFill className="text-primary mr-2"/>
                                <p>
                                    Funciona 24h/7 dias da semana
                                </p>
                            </item>
                            <item className="flex items-center">
                                <BsFillArrowRightSquareFill className="text-primary mr-2"/>
                                <p>
                                    A IA trabalha com os 12 maiores jogos do mundo
                                </p>
                            </item>
                            <item className="flex items-center">
                                <BsFillArrowRightSquareFill className="text-primary mr-2"/>
                                <p>
                                    Funciona de qualquer lugar do mundo
                                </p>
                            </item>
                        </list>
                        <div>
                            <h1 className="text-lg">
                                TUDO POR APENAS <b className="text-primary text-5xl lg:text-4xl font-extrabold">R$19,90</b> COM <b className="text-primary">ACESSO VITALÍCIO</b>
                            </h1>
                        </div>
                    </article>
                    <a className="cursor-pointer bg-primary text-background font-extrabold p-2 w-11/12 lg:w-10/12 text-center mt-2 rounded-lg" href="https://pepper.com.br/checkout/index.html?p=93475&o=107543" target="_blank">COMPRAR AGORA</a>
                </section>

                <section className="flex flex-col p-2 py-6 lg:p-6 lg:py-6 rounded-lg w-full bg-second my-10 items-center justify-center">
                    <div className="items-center justify-center flex flex-col w-11/12 lg:w-10/12">
                        <h2 className="font-bold text-xl">
                            Perguntas frequentes
                        </h2>

                        <div className="mt-4 py-4 border-b border-contrast">
                            <h3 className="font-bold">
                                1. Como vou receber?
                            </h3>
                            <p className="text-left w-10/12">
                            Todas as instruções serão entregues pelo e-mail que foi cadastrado na hora da compra. Neste mesmo e-mail irá conter todo o passo a passo para acessar o aplicativo.
                            </p>
                        </div>

                        <div className="py-4 border-b border-contrast">
                            <h3 className="font-bold">
                                2. Preciso depositar na casa?
                            </h3>
                            <p className="text-left w-10/12">
                                Sim, é necessário fazer um depósito dentro da plataforma que irá fazer as entradas, pois o que a IA faz é alavancar o seu dinheiro depositado através das entradas que serão geradas de forma automática dentro do aplicativo. A única coisa que o cliente precisa fazer é dar as entradas, e tudo isso pode fazer também dentro do aplicativo.
                            </p>
                        </div>

                        <div className="py-4 border-b border-contrast">
                            <h3 className="font-bold">
                                3. Quais são os valores para cada entrada dentro da plataforma?
                            </h3>
                            <p className="text-left w-10/12">
                                A plataforma que será enviada através do e-mail permite fazer entradas de 50 centavos até 500 reais, isso é muito positivo porque permite alavancagem de bancas baixas e altas ao mesmo tempo.
                            </p>
                        </div>

                        <div className="py-4 border-b border-contrast">
                            <h3 className="font-bold">
                                4. Qual o valor mínimo do depósito dentro da plataforma?
                            </h3>
                            <p className="text-left w-10/12">
                                O valor mínimo dentro da plataforma é de R$40,00 com o único método de pagamento sendo PIX.
                            </p>
                        </div>

                        <div className="py-4 mb-2">
                            <h3 className="font-bold">
                                5. Preciso fazer o depósito para ter acesso ao aplicativo?
                            </h3>
                            <p className="text-left w-10/12">
                            Não, assim que efetuado a compra e criado o seu cadastro dentro da plataforma o acesso já é entregue automaticamente também pelo e-mail. Com isso terá acesso a todos os 12 jogos que o aplicativo proporciona. Porém, é importante destacar que o depósito é necessário para fazer as entradas dentro da plataforma.
                            <br/><b>O aplicativo é um acessório de alavancagem.</b>
                            </p>
                        </div>
                        
                    </div>

                    <div className="flex flex-col justify-center items-center m-4 w-11/12 lg:w-10/12">
                        <p>
                            Caso tenha alguma dúvida nos chame no WhatsApp, clicando no botão abaixo:
                        </p>
                        <a className="cursor-pointer bg-primary text-background font-extrabold p-2 w-full text-center mt-2 rounded-lg" href="https://wa.me/+5554991423251?text=Oii!%20Estou%20com%20d%C3%BAvidas%20sobre%20a%20Echo%20IA,%20poderia%20me%20ajudar?" target="_blank">CHAMAR NO WHATSAPP</a>
                    </div>
                </section>
            </article>

            <footer className="flex flex-col justify-center items-center mb-8">
                <div className="flex">
                    <a target="_blank" href="https://www.instagram.com/iaecho/">
                        <img className="w-8 m-2 cursor-pointer" src="/instagram.svg"/>
                    </a>
                    <a href="mailto:sac@echoia.pro" target="_blank">
                        <img className="w-8 m-2 cursor-pointer" src="/email.svg"/>
                    </a>
                    <a target="_blank" href="https://wa.me/+5554991423251?text=Oii!%20Preciso%20de%20ajuda%20com%20o%20aplicativo%20Echo%20IA!">
                        <img className="w-8 m-2 cursor-pointer" src="/chat.svg"/>
                    </a>
                    
                </div>
                <h3 className="font-bold">
                    Powered by Echo IA©
                </h3>
            </footer>
        </main>
    )
}
