import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PaginaA from './pages/pagina-a';
import Error from './pages/error';
import Obrigado from './pages/obrigado';

function RoutesApp(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/pagina-a' element={<PaginaA/>}/>
                <Route path='/obrigado' element={<Obrigado/>}/>
                <Route path='*' element={<Error/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp;