import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class Carousel extends React.Component {
  render() {
    const settings = {
      dots: true, // Exibir pontos de paginação
      infinite: true, // Continuar girando infinitamente
      speed: 500, // Velocidade da transição (ms)
      slidesToShow: 1, // Número de slides visíveis por vez
      slidesToScroll: 1, // Número de slides a serem rolados
      autoplay: true, // Ativar o modo automático
      autoplaySpeed: 2000, // Tempo de intervalo entre as transições (ms)
    };

    return (
      <Slider {...settings} className="w-full p-2 m-2 lg:w-1/2 h-1/2">
        <div>
          <img src='/1.webp'/>
        </div>
        <div>
        <img src='/2.webp'/>
        </div>
        <div>
        <img src='/3.webp'/>
        </div>
        <div>
        <img src='/4.webp'/>
        </div>
        <div>
        <img src='/5.webp'/>
        </div>
        <div>
        <img src='/6.webp'/>
        </div>
      </Slider>
    );
  }
}

export default Carousel;